










































































































































import Bop from "@/models/Bop";
import OperationService from "@/services/OperationService";
import {
  BopModelEnum,
  CoefficientTypeEnum,
  OperationType,
} from "@/utils/Enums";
import Constants from "@/utils/Constants";

import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Guid } from "guid-typescript";
import BopService from "@/services/BopService";
import CoefficientService from "@/services/CoefficientService";
import Coefficient from "@/models/Coefficient";
import CoefficientOptionService from "@/services/CoefficientOptionService";
import BopModelService from "@/services/BopModelService";
import OperationGroup from "@/models/OperationGroup";
import { evaluate } from "mathjs";
import BopModel from "@/models/BopModel";
import OperationTypeService from "@/services/OperationTypeService";
import CoefficientConfig from "@/models/CoefficientConfig";
const REVETEMENT_TOLE_COEFFICIENT_KEY = "RevetementTole";
const TYPE_ISOLATION_COEFFICIENT_KEY = "TypeIsolation";
const EPAISSEUR_ISOLATION_COEFFICIENT_KEY = "EpaisseurIsolation";
const NATURE_APPAREIL_COEFFICIENT_KEY = "NatureAppareil";
const Hauteur_COEFFICIENT_KEY = "Hauteur";

const EPAISSEUR_COEFFICIENT_KEY = "epaisseur";

const NATURE_TACHE_COEFFICIENT_KEY = "K2";
// const MONTAGE_SEULE_VALUE = 0.15;
// const NATURE_APPAREIL_CHAUD_VALUE = -1;
const MONTAGE_DEMONTAGE_OPERATION_FAMILY_KEY = "montageDemontage";
const FOURNITURES_OPERATION_FAMILY_KEY = "fournitures";
const MONTAGE_OPERATION_FAMILY_KEY = "montage";
const DEMONTAGE_OPERATION_FAMILY_KEY = "demontage";
const MONTAGE_DEMONTAGE_OPERATION_DEFINER_COEFFICIENTS_KEYS = [
  REVETEMENT_TOLE_COEFFICIENT_KEY,
  NATURE_APPAREIL_COEFFICIENT_KEY,
  TYPE_ISOLATION_COEFFICIENT_KEY,
  EPAISSEUR_ISOLATION_COEFFICIENT_KEY,
  NATURE_TACHE_COEFFICIENT_KEY,
];
const INITIALIZED_GLOBAL_COEFFICIENTS: Array<string> = [
  NATURE_APPAREIL_COEFFICIENT_KEY,
  Hauteur_COEFFICIENT_KEY,
];

@Component({
  components: {
    BopSetupTable: () => import("@/components/Shared/Table/BopSetupTable.vue"),
    OperationComponent: () =>
      import("@/components/BopSetup/shared/OperationComponent.vue"),
  },
})
export default class OperationPrincipalCaStep extends Vue {
  @Prop()
  private isNotReadOnly!: boolean;
  private get canBeEditedAfterRealised(): boolean {
    return (
      this.$route.query.isEdit !== undefined &&
      this.$route.query.isEdit === "true"
    );
  }
  private get isEditable(): boolean {
    return this.canBeEditedAfterRealised || this.isNotReadOnly;
  }
  private fournitureVisibility: any = {};
  private haveT1Coefficients: boolean = false;
  private operationGroups: Array<OperationGroup> = [];
  private operationGroupCoefficientFields: Array<any> = [];
  // operation groupe coefficient fields selected values
  private ogCoefficientFieldsSelectedVals: Array<any> = [];
  private globalCoefficients: any = {};
  private unitValuePointIsDefinedByCoefficient: boolean = false;
  private columnsPerFamily: any = {};
  private coefficientsPerFamily: any = {};
  private operationGroupsTotalPoints: any = {};
  private selectedGlobalCoefficients: any = {};
  // private lastSelectedFournituresFamilies: Array<string> = [];
  private MONTAGE: any = null;
  private DEMONTAGE: any = null;
  // private isloaded: boolean = false;
  private fournitures: any = {};
  private;
  private get bopShort(): String {
    return `${
      this.bop.siteName
    } - ${this.bop.bopModelName?.toString().toLowerCase()} ${
      this.bop.bopNumber ? " # " : ""
    }${this.bop.bopNumber ?? ""}`;
  }
  private operationGroupNumber: number = 0;
  private get selectedBopModel(): BopModel {
    return this.$store.state.selectedBopModel;
  }
  private tableKey: string = "";

  private get allTotalPoints() {
    return this.bop.totalOperationPrincipalPoints;
  }

  private get bop() {
    return this.$store.state.bop;
  }
  private set bop(value: Bop) {
    this.$store.commit("changeBop", value);
  }
  private get bopStatus() {
    return this.$store.state.bopStatuses[this.bop.bopStatusKey];
  }
  private get isGlobalCoefficientsVisible() {
    return Object.keys(this.globalCoefficients).length;
  }

  private get language() {
    return this.$i18n.locale;
  }
  private canShowTypeIsolation: boolean = true;
  private operationIdsToRemove: Array<string> = [];
  private bopOperationMontageDemontageOptions: Array<any> = [];
  private isMontageAdded: boolean = false;
  private isDemontageAdded: boolean = false;
  private get isLoaded(): boolean {
    const length = this.operationGroups.filter(
      (og: OperationGroup) => !og.canBeDeleted
    ).length;

    return this.operationGroupCounter === length;
  }
  private operationGroupCounter: number = 0;
  private get isBopReadOnly(): boolean {
    return (
      Bop.readOnlyStatuses.includes(this.bop.bopStatusKey) &&
      !this.canBeEditedAfterRealised
    );
  }
  /**
   * ###########
   * methods
   * ###########
   * */
  private handleFournitureClick(operationGroupId: string) {
    if (this.fournitureVisibility[operationGroupId] === undefined) {
      this.$set(this.fournitureVisibility, operationGroupId, false);
    } else {
      this.$set(
        this.fournitureVisibility,
        operationGroupId,
        !this.fournitureVisibility[operationGroupId]
      );
    }
  }
  private onFullyLoaded() {
    this.operationGroupCounter++;
    // if (this.isLoaded) {
    this.$emit("fully-loaded");
    // }
  }
  private removeOperationGroup(operationGroupIndex) {
    if (operationGroupIndex === -1) return;

    this.operationGroups[operationGroupIndex].canBeDeleted = true;
    this.bop.operationPrincipalGroups[operationGroupIndex].canBeDeleted = true;
  }
  private resetSelectedOperations(selectedCoeffId) {
    if (!this.isEditable) return;
    this.operationGroupCounter = 0;

    const operationIdsToRemove: Array<string> = [];
    this.operationGroups.forEach((og: OperationGroup) => {
      og.operations.forEach((op: any) => {
        operationIdsToRemove.push(op.id);
      });
      og.operations = [];
    });
    const ogString = JSON.stringify(this.operationGroups);
    this.bop.operationPrincipalGroups = JSON.parse(ogString);
    this.operationGroups = JSON.parse(ogString);

    this.$store.commit("changeTotalOperationPrincipalPoints", 0);

    this.bop.deletedOperations = operationIdsToRemove.concat(
      this.bop.deletedOperations ?? []
    );
    this.tableKey = selectedCoeffId;
  }

  /**
   * On global coefficient change event
   */
  private async globalCoefficientChanged(selectedId: string, coeff: any) {
    // if nature appareil is selected we make sure to disable the type isolation global coefficient field
    if (coeff.coeffKey === NATURE_APPAREIL_COEFFICIENT_KEY) {
      const selectedCoefficient: CoefficientConfig = coeff.options.find(
        (el: any) => el.key.id === selectedId
      ).key;
      if (!selectedCoefficient.flagAction) {
        this.selectedGlobalCoefficients[TYPE_ISOLATION_COEFFICIENT_KEY] =
          Constants.DEFAULT_VALUE_EMPTY_OPTIONS;
        this.canShowTypeIsolation = false;
      } else {
        if (
          this.selectedGlobalCoefficients[TYPE_ISOLATION_COEFFICIENT_KEY] ===
          Constants.DEFAULT_VALUE_EMPTY_OPTIONS
        ) {
          delete this.selectedGlobalCoefficients[
            TYPE_ISOLATION_COEFFICIENT_KEY
          ];
        }
        this.canShowTypeIsolation = true;
      }
      // if the selected coefficient is Nature tache
    } else if (coeff.coeffKey === NATURE_TACHE_COEFFICIENT_KEY) {
      // handling montage demontage
      //we create the montage demontage bloc and we handle their visibility based on the coeff flagAction for demontage nad isMontageVisible for montage

      if (
        this.operationGroups.findIndex(
          (og) => og.operationFamilyKey === DEMONTAGE_OPERATION_FAMILY_KEY
        ) === -1
      ) {
        const operationGroup = new OperationGroup();
        operationGroup.operationFamilyName = this.$t(
          "caloApp.demontage"
        ).toString();
        operationGroup.operationFamilyId = this.DEMONTAGE.id;
        operationGroup.operationFamilyKey = DEMONTAGE_OPERATION_FAMILY_KEY;
        operationGroup.number = this.operationGroupNumber;
        this.bop.operationPrincipalGroups.push(operationGroup);
        const jsonOg = JSON.stringify(operationGroup);
        this.operationGroups.push(JSON.parse(jsonOg));
        this.isDemontageAdded = true;
        this.operationGroupNumber++;
      }
      if (
        this.operationGroups.findIndex(
          (og) => og.operationFamilyKey === MONTAGE_OPERATION_FAMILY_KEY
        ) === -1
      ) {
        const operationGroup = new OperationGroup();
        operationGroup.operationFamilyName = this.$t(
          "caloApp.montage"
        ).toString();
        operationGroup.operationFamilyId = this.MONTAGE.id;
        operationGroup.operationFamilyKey = MONTAGE_OPERATION_FAMILY_KEY;
        operationGroup.number = this.operationGroupNumber;

        this.bop.operationPrincipalGroups.push(operationGroup);
        const jsonOg = JSON.stringify(operationGroup);
        this.operationGroups.push(JSON.parse(jsonOg));
        this.isMontageAdded = true;
        this.operationGroupNumber++;
      }
      const selectedCoefficient: CoefficientConfig = coeff.options.find(
        (el: any) => el.key.id === selectedId
      ).key;
      //secondValue is for montage the value is for demontage

      // we need the value to know if its a montage seule so we have to
      //remove the demontage part or montage demontage and add the demontage part
      if (!selectedCoefficient.flagAction) {
        this.removeOperationGroup(0);
      } else {
        this.operationGroups[0].canBeDeleted = false;
        this.bop.operationPrincipalGroups[0].canBeDeleted = false;
      }
      if (!selectedCoefficient.isMontageVisible) {
        this.removeOperationGroup(1);
      } else {
        this.operationGroups[1].canBeDeleted = false;
        this.bop.operationPrincipalGroups[1].canBeDeleted = false;
      }

      //handling fournitures

      const fournituresOperationFamily = this.fournitures[
        selectedCoefficient.id
      ];
      const currentFournitureIds = fournituresOperationFamily.map(
        (f) => f.operationFamilyId
      );
      // array of ids of fournitures
      let existingFournitures: Array<any> = [];
      this.operationGroups.forEach((og: OperationGroup, index: number) => {
        if (
          !currentFournitureIds.includes(og.operationFamilyId) &&
          og.operationFamilyKey !== MONTAGE_OPERATION_FAMILY_KEY &&
          og.operationFamilyKey !== DEMONTAGE_OPERATION_FAMILY_KEY
        ) {
          this.removeOperationGroup(index);
        } else if (
          og.operationFamilyKey !== MONTAGE_OPERATION_FAMILY_KEY &&
          og.operationFamilyKey !== DEMONTAGE_OPERATION_FAMILY_KEY
        ) {
          this.operationGroups[index].canBeDeleted = false;
          this.bop.operationPrincipalGroups[index].canBeDeleted = false;
          existingFournitures.push(og.operationFamilyId);
        }
      });
      fournituresOperationFamily.forEach((fof: any) => {
        if (existingFournitures.includes(fof.operationFamilyId)) return;
        const operationGroup = new OperationGroup();
        this.$set(this.fournitureVisibility, operationGroup.id, false);
        operationGroup.operationFamilyName = fof.operationFamilyName;
        operationGroup.isFourniture = true;
        operationGroup.number = this.operationGroupNumber;
        operationGroup.operationFamilyId = fof.operationFamilyId;
        operationGroup.operationFamilyKey = fof.operationFamilyKey;
        this.operationGroupNumber++;

        this.bop.operationPrincipalGroups.push(operationGroup);
        const ogString = JSON.stringify(operationGroup);
        this.operationGroups.push(JSON.parse(ogString));
        const columnCoefficientsDefiningOperations: Array<any> = [];
        const columnCoefficients: Array<any> = [];
        const fieldCoefficient: Array<any> = [];
        fof.coefficients.forEach((coeff: Coefficient) => {
          const coeffColumn = {
            name: "bopCoefficientDropDown",
            title: coeff.name,
            property: `coefficientSelectedValues.${coeff.key}.coefficientOptionId`,
            // display: "operationName",
            coefficient: coeff,
            // options: coefficientOptions,
            dataContainer: `coefficients.${coeff.key}`,
            isNotEditable: !this.isEditable,
          };
          fieldCoefficient.push({ key: coeff.key, id: coeff.id });

          if (coeff.definesOperationUnitPoint) {
            columnCoefficientsDefiningOperations.push(coeffColumn);
          } else {
            columnCoefficients.push(coeffColumn);
          }
        });
        this.columnsPerFamily[fof.operationFamilyId] = this.buildColumns(
          columnCoefficientsDefiningOperations,
          columnCoefficients,
          fof.functionUnitPoints,
          fof.functionOperationUnitPoints
        );
        this.coefficientsPerFamily[fof.operationFamilyId] = fieldCoefficient;
        OperationService.getBopCalorifugeageAppareilOperations(
          null,
          null,
          null,
          null,
          null,
          fof.operationFamilyKey,
          this.language
        ).then((response) => {
          const bopOperationOptions = response.data.map((el: any) => ({
            key: el,
            value: el.name,
          }));
          let config = {
            operationGroupId: operationGroup.id,
            bopOperationOptions,
          };
          // let config = {
          //   operationGroupId: this.operationGroups.find(
          //     (ogo) => ogo.operationFamilyKey === MONTAGE_OPERATION_FAMILY_KEY
          //   )?.id,
          //   bopOperationOptions,
          // };
          // this.$store.commit("changeBopOperationOptions", config);
          this.$store.commit("changeBopOperationOptions", config);
        });
      });
    }
    // the changes that can trigger the load is based on the coefficients in the constant array
    if (
      MONTAGE_DEMONTAGE_OPERATION_DEFINER_COEFFICIENTS_KEYS.includes(
        coeff.coeffKey
      )
    ) {
      this.resetSelectedOperations(selectedId);
      await this.loadMontageDemontageOperations();
    }
  }
  /**
   * Loads the operation based on the global coefficients
   */
  private async loadMontageDemontageOperations() {
    const revetementTole = this.selectedGlobalCoefficients[
      REVETEMENT_TOLE_COEFFICIENT_KEY
    ];
    const natureAppareil = this.selectedGlobalCoefficients[
      NATURE_APPAREIL_COEFFICIENT_KEY
    ];
    const typeIsolation = this.selectedGlobalCoefficients[
      TYPE_ISOLATION_COEFFICIENT_KEY
    ];
    const epaisseurIsolation = this.selectedGlobalCoefficients[
      EPAISSEUR_ISOLATION_COEFFICIENT_KEY
    ];
    if (revetementTole && natureAppareil && epaisseurIsolation) {
      const data = (
        await OperationService.getBopCalorifugeageAppareilOperations(
          revetementTole,
          natureAppareil,
          typeIsolation === Constants.DEFAULT_VALUE_EMPTY_OPTIONS
            ? null
            : typeIsolation,
          epaisseurIsolation,
          null,
          MONTAGE_DEMONTAGE_OPERATION_FAMILY_KEY,
          this.language
        )
      ).data.map((el: any) => ({
        key: el,
        value: el.name,
      }));
      this.bopOperationMontageDemontageOptions = data;
    }
    // after loading the operations we affect them to the correct operation group
    const demontageOperationGroupId = this.operationGroups.find(
      (ogo) => ogo.operationFamilyKey === DEMONTAGE_OPERATION_FAMILY_KEY
    )?.id;
    if (demontageOperationGroupId) {
      const configDemontage = {
        operationGroupId: demontageOperationGroupId,
        bopOperationOptions: this.bopOperationMontageDemontageOptions,
      };
      this.$store.commit("changeBopOperationOptions", configDemontage);
    }
    const montageOperationGroupId = this.operationGroups.find(
      (ogo) => ogo.operationFamilyKey === MONTAGE_OPERATION_FAMILY_KEY
    )?.id;
    if (montageOperationGroupId) {
      const configMontage = {
        operationGroupId: montageOperationGroupId,
        bopOperationOptions: this.bopOperationMontageDemontageOptions,
      };
      this.$store.commit("changeBopOperationOptions", configMontage);
    }
  }
  private onGlobalCoefficientSelectChange($event: any, coeff: any) {
    Vue.set(this.selectedGlobalCoefficients, coeff.coeffKey, $event);
    this.$forceUpdate();
  }

  private onOperationChange(operationDictionary: any) {
    const operationGroupIndex = this.bop.operationPrincipalGroups.findIndex(
      (og) => og.id === operationDictionary.key
    );
    const operations = operationDictionary.value;
    this.$store.commit("changeBopOperationPrincipal", {
      operationGroupIndex,
      operations,
    });
    // console.groupEnd();
  }

  private async onTotalPointsChanged(totalPoints: any) {
    this.$set(
      this.operationGroupsTotalPoints,
      totalPoints.key,
      totalPoints.value
    );
  }
  private calculate() {
    if (!this.isEditable) return;
    const calculusFunction = this.selectedBopModel.functionTotalUnitPoints;
    if (!calculusFunction) return;
    const totalValues = Object.values(this.operationGroupsTotalPoints);
    if (!totalValues.length) return;
    const sum = <number>(
      totalValues.reduce(
        (accumulator, currentValue) =>
          <number>accumulator + <number>currentValue
      )
    );
    if (calculusFunction === "{sum}") {
      const result = Math.round((sum + Number.EPSILON) * 100) / 100;
      this.$store.commit("changeTotalOperationPrincipalPoints", result);
      return;
    }
    const regex = /{[\w.]*}/gm;
    let m;
    let formulaElements: Array<any> = [];
    while ((m = regex.exec(calculusFunction)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }

      // The result can be accessed through the `m`-variable.
      for (let i = 0; i < m.length; i++) {
        const match = m[i];
        const path: string = match.replace(/{|}/gm, "");
        //replacing the {key} with the corresponding value
        let value = "";
        if (path.indexOf("globalCoefficient") !== -1) {
          const lastIndexOfDot = path.indexOf(".");
          const key = path.substring(lastIndexOfDot + 1);
          /**
           * if the coefficient is not yet selectionned no need to continue further calculus and parsing
           */
          if (!this.$store.state.selectedGlobalCoefficients[key]) {
            this.$store.commit("changeTotalOperationPrincipalPoints", 0);
            return;
          }
          value = this.$store.state.selectedGlobalCoefficients[key].value;
        } else if (path === "sum") {
          value = sum.toString();
        }
        formulaElements.push({
          key: match,
          value,
        });
      }
    }
    let equation = calculusFunction;
    formulaElements.forEach((el: any) => {
      equation = equation.replace(el.key, el.value);
    });
    let result = evaluate(equation);
    result = Math.round((result + Number.EPSILON) * 100) / 100;

    this.$store.commit("changeTotalOperationPrincipalPoints", result);
    // console.groupEnd();
  }

  private buildColumns(
    columnCoefficientsDefiningOperations: Array<any>,
    columnCoefficients: Array<any>,
    totalPointsFormula: string = "",
    unitValueFormula: string = "",
    montageDemontageKey: string = ""
  ) {
    const columns: Array<any> = [];

    columns.push({
      name: "quantity",
      title: this.$t("bopSetup.operationQuantity").toString(),
      property: "quantity",
      isNotEditable: !this.isEditable,
    });

    if (columnCoefficientsDefiningOperations.length) {
      columns.push(...columnCoefficientsDefiningOperations);
      columns.push({
        name: "disabledDynamicInput",
        fieldKey: "unitValueFormula",

        title: this.$t("bopSetup.operationUnitPoints").toString(),
        formula: unitValueFormula,
        titleClass: "hidden",
        dataClass: "hidden",
      });
    } else {
      columns.push({
        name: "disabledDynamicInput",
        fieldKey: "unitValueFormula",

        title: this.$t("bopSetup.operationUnitPoints").toString(),
        formula: unitValueFormula,
        titleClass: "hidden",
        dataClass: "hidden",
      });
    }
    if (columnCoefficients.length) {
      columns.push(...columnCoefficients);
    }
    columns.push({
      name: "disabledDynamicInput",
      title: this.$t("bopSetup.total").toString(),
      fieldKey: "totalPointsFormula",
      property: "totalPoints",
      formula: totalPointsFormula,
      montageDemontageKey,
    });

    return columns;
  }
  private async loadData() {
    if (!this.bop.bopModelId) return;
    if (!this.selectedBopModel) {
      const bm = (
        await BopModelService.getBopModel(this.bop.bopModelId, this.language)
      ).data;
      this.$store.commit("changeSelectedBopModel", bm);
    }
    this.MONTAGE = (
      await OperationTypeService.findOperationTypeByKey(
        "montage",
        this.language
      )
    ).data;
    this.DEMONTAGE = (
      await OperationTypeService.findOperationTypeByKey(
        "demontage",
        this.language
      )
    ).data;
    let columnsPerFamily: any = {};
    let coefficientsPerFamily: any = {};
    if (this.bop.operationPrincipalGroups.length) {
      this.bop.operationPrincipalGroups.forEach(async (og: OperationGroup) => {
        const fournitureOperationFamily = (
          await OperationTypeService.ListOperationFamiliesAndCoefficients(
            this.bop.bopModelId ?? "",
            this.language,
            og.operationFamilyKey
          )
        ).data[0];
        //building only fournitures columns
        if (!fournitureOperationFamily) return;
        OperationService.getBopCalorifugeageAppareilOperations(
          null,
          null,
          null,
          null,
          null,
          fournitureOperationFamily.operationFamilyKey,
          this.language
        ).then((response) => {
          const bopOperationOptions = response.data.map((el: any) => ({
            key: el,
            value: el.name,
          }));
          let config = {
            operationGroupId: og.id,
            bopOperationOptions,
          };

          this.$store.commit("changeBopOperationOptions", config);
        });
        // lastSelectedFournituresFamilies.push(
        //   fournitureOperationFamily.operationFamilyId
        // );
        const columnCoefficientsDefiningOperations: Array<any> = [];
        const columnCoefficients: Array<any> = [];
        const fieldCoefficients: Array<any> = [];

        fournitureOperationFamily.coefficients.forEach((coeff: Coefficient) => {
          const coeffColumn = {
            name: "bopCoefficientDropDown",
            title: coeff.name,
            property: `coefficientSelectedValues.${coeff.key}.coefficientOptionId`,
            // display: "operationName",
            coefficient: coeff,
            // options: coefficientOptions,
            dataContainer: `coefficients.${coeff.key}`,
            isNotEditable: !this.isEditable,
          };
          fieldCoefficients.push({ key: coeff.key, id: coeff.id });

          if (coeff.definesOperationUnitPoint) {
            columnCoefficientsDefiningOperations.push(coeffColumn);
          } else {
            columnCoefficients.push(coeffColumn);
          }
        });
        columnsPerFamily[
          fournitureOperationFamily.operationFamilyId
        ] = this.buildColumns(
          columnCoefficientsDefiningOperations,
          columnCoefficients,
          fournitureOperationFamily.functionUnitPoints,
          fournitureOperationFamily.functionOperationUnitPoints
        );
        coefficientsPerFamily[
          fournitureOperationFamily.operationFamilyId
        ] = fieldCoefficients;
      });
      // this.lastSelectedFournituresFamilies = lastSelectedFournituresFamilies;
    }
    const montageDemontageColumnCoefficients: Array<any> = [];
    const columnCoefficientsDefiningOperations: Array<any> = [];
    let counter = 0;

    for (let key in this.bop.selectedGlobalCoefficients) {
      Vue.set(
        this.selectedGlobalCoefficients,
        key,
        this.bop.selectedGlobalCoefficients[key].coefficientOptionId
      );
      counter++;
    }

    this.$store.commit(
      "changeSelectedGlobalCoefficients",
      this.bop.selectedGlobalCoefficients
    );
    const coefficients = (
      await CoefficientService.getBopModelCoefficientTypes(
        this.bop.bopModelId,
        this.$i18n.locale
      )
    ).data;
    const operationGroupCoefficientFields: Array<any> = [];
    const globalCoefficients: any = {};
    for (let i = 0; i < coefficients.length; i++) {
      const coeff: Coefficient = coefficients[i];
      if (coeff.coefficientType === CoefficientTypeEnum.T4) {
        const options: Array<any> = (
          await CoefficientOptionService.GetCoefficientOptionsByCoefficient(
            coeff.id,
            this.$i18n.locale
          )
        ).data;
        if (coeff.key == NATURE_TACHE_COEFFICIENT_KEY) {
          await Promise.all(
            options.map(async (coefficientOption: any) => {
              this.fournitures[coefficientOption.id] = (
                await OperationTypeService.ListOperationFamiliesAndCoefficients(
                  this.bop.bopModelId ?? "",
                  this.language,
                  null,
                  coefficientOption.id
                )
              ).data;
            })
          );
        }
        const coefficientOptions = options.map((el: any) => {
          const value =
            coeff.coefficientType == CoefficientTypeEnum.T2
              ? el.value
              : el.name;
          return {
            key: el,
            value,
          };
        });

        let opGlobalField: any = {};
        //  if (coeff.coefficientType == CoefficientTypeEnum.T0) {
        opGlobalField = {
          id: coeff.id,
          name: coeff.name,
          coeffKey: coeff.key,
          coefficientType: coeff.coefficientType,
          options: coefficientOptions,
        };

        globalCoefficients[coeff.key] = opGlobalField;
      }
    }
    const montageDemontageOperationFamily = (
      await OperationTypeService.ListOperationFamiliesAndCoefficients(
        this.bop.bopModelId,
        this.language,
        MONTAGE_DEMONTAGE_OPERATION_FAMILY_KEY
      )
    ).data[0];
    const montageDemontageFieldCoefficients: Array<any> = [];
    for (
      let i = 0;
      i < montageDemontageOperationFamily.coefficients.length;
      i++
    ) {
      const coeff: Coefficient =
        montageDemontageOperationFamily.coefficients[i];
      if (coeff.coefficientType === CoefficientTypeEnum.T0) {
        montageDemontageColumnCoefficients.push({
          name: "coefficientEditable",
          title: coeff.name,
          coefficient: coeff,
          property: `coefficientSelectedValues.${coeff.key}.value`,
          dataContainer: `coefficients.${coeff.key}.value`,
          isNotEditable: !this.isEditable,
        });
      } else if (
        coeff.coefficientType == CoefficientTypeEnum.T2 ||
        coeff.coefficientType == CoefficientTypeEnum.T3
      ) {
        montageDemontageColumnCoefficients.push({
          name: "bopCoefficientDropDown",
          title: coeff.name,
          property: `coefficientSelectedValues.${coeff.key}.coefficientOptionId`,
          // display: "operationName",
          coefficient: coeff,
          // options: coefficientOptions,
          dataContainer: `coefficients.${coeff.key}`,
          isNotEditable: !this.isEditable,
        });
      }
      montageDemontageFieldCoefficients.push({ key: coeff.key, id: coeff.id });
    }
    coefficientsPerFamily[this.MONTAGE.id] = montageDemontageFieldCoefficients;
    coefficientsPerFamily[
      this.DEMONTAGE.id
    ] = montageDemontageFieldCoefficients;

    columnsPerFamily[this.MONTAGE.id] = this.buildColumns(
      [],
      montageDemontageColumnCoefficients,
      "",
      "",
      MONTAGE_OPERATION_FAMILY_KEY
    );
    columnsPerFamily[this.DEMONTAGE.id] = this.buildColumns(
      [],
      montageDemontageColumnCoefficients,
      "",
      "",
      DEMONTAGE_OPERATION_FAMILY_KEY
    );
    await Vue.nextTick();

    this.coefficientsPerFamily = coefficientsPerFamily;
    // this.selectedCoeffGroup = {...selectedCoeffGroup};
    this.operationGroupCoefficientFields = operationGroupCoefficientFields;
    this.globalCoefficients = globalCoefficients;
    this.operationGroups = JSON.parse(
      JSON.stringify(this.bop.operationPrincipalGroups)
    );
    this.canShowTypeIsolation = this.bop.selectedGlobalCoefficients[
      NATURE_APPAREIL_COEFFICIENT_KEY
    ]?.flagAction;
    if (!this.canShowTypeIsolation) {
      this.bop.selectedGlobalCoefficients[TYPE_ISOLATION_COEFFICIENT_KEY] = {
        coefficientOptionId: Constants.DEFAULT_VALUE_EMPTY_OPTIONS,
      };
    }
    if (counter > 3) {
      await this.loadMontageDemontageOperations();
    }
    this.columnsPerFamily = columnsPerFamily;
    this.operationGroupsTotalPoints = {};
  }
  /**
   * ############
   * Hooks
   * ############
   */
  private created() {
    this.loadData().then(() => {
      if (this.selectedGlobalCoefficients[NATURE_TACHE_COEFFICIENT_KEY]) {
        const currentFournitures = this.fournitures[
          this.selectedGlobalCoefficients[NATURE_TACHE_COEFFICIENT_KEY]
        ];
        this.operationGroups.forEach((og: OperationGroup, index: number) => {
          const isFourniture =
            currentFournitures.findIndex(
              (f) => f.operationFamilyKey === og.operationFamilyKey
            ) !== -1;
          og.isFourniture = isFourniture;
          this.bop.operationPrincipalGroups[index].isFourniture = isFourniture;
        });
      }
      this.$forceUpdate();
      if (this.isLoaded) {
        INITIALIZED_GLOBAL_COEFFICIENTS.forEach((coeffKey) => {
          if (!this.selectedGlobalCoefficients[coeffKey])
            this.selectedGlobalCoefficients[coeffKey] = this.globalCoefficients[
              coeffKey
            ].options.sort((a, b) => a.key.value - b.key.value)[0].key.id;
        });
        this.$emit("fully-loaded");
      }
    });
  }
  // private mounted() {
  //   this.isloaded = true;
  // }
  @Watch("selectedBopModel.key")
  private async onBopModelChange() {
    this.loadData().then(() => {
      if (this.isLoaded) {
        this.$emit("fully-loaded");
      }
    });
  }
  //watchers
  @Watch("operationGroupsTotalPoints", { deep: true })
  onOperationGroupsTotalPoints() {
    this.calculate();
  }
  @Watch("selectedGlobalCoefficients", { deep: true })
  private async onSelectedGlobalCoefficients() {
    let selectedGlobalCoeffs = {};
    //this.globalCoefficients.find(el=> el.options.key.id === this.selectedGlobalCoefficients))
    // this.bop.selectedGlobalCoefficients = this.selectedGlobalCoefficients;
    if (this.isBopReadOnly || !Object.keys(this.globalCoefficients).length)
      return;
    for (let key in this.selectedGlobalCoefficients) {
      if (!this.globalCoefficients[key]) continue;
      const val = this.selectedGlobalCoefficients[key];
      const coeff = this.globalCoefficients[key].options.find(
        (op) => op.key.id === val
      )?.key;
      selectedGlobalCoeffs[key] = coeff;
      if (!this.bop.selectedGlobalCoefficients[key]) {
        this.bop.selectedGlobalCoefficients[key] = {};
      }
      if (val === Constants.DEFAULT_VALUE_EMPTY_OPTIONS) {
        this.bop.selectedGlobalCoefficients[key].coefficientOptionId = val;
      } else if (coeff && coeff.id && coeff.coefficientId) {
        this.bop.selectedGlobalCoefficients[key].coefficientOptionId = coeff.id;
        this.bop.selectedGlobalCoefficients[key].coefficientId =
          coeff.coefficientId;
        this.bop.selectedGlobalCoefficients[key].value = coeff?.value;
        this.bop.selectedGlobalCoefficients[key].secondValue =
          coeff?.secondValue;
        this.bop.selectedGlobalCoefficients[
          key
        ].operationFamilyCoefficientOptionList =
          coeff?.operationFamilyCoefficientOptionList;
      } else {
        this.bop.selectedGlobalCoefficients[key] = null;
      }
    }
    this.$store.commit(
      "changeSelectedGlobalCoefficients",
      selectedGlobalCoeffs
    );
    this.calculate();
  }
}
